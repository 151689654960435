import { useState } from "react"
import s from "./name-cost.module.css"
import Warning from "../warning/warning"

export default function NameCost({title, term, price}){

    const [warning, setWarning] = useState(false)

    return(<div className={s.name_description_buy}>
        <h1 className={s.title}>{title}</h1>
        <aside className={s.description}>{term}</aside>
        <button className={s.buy} onClick={() => setWarning(true)}>{price} ₽</button>
        {warning && <Warning openPopup={setWarning}/>}
    </div>)
}