import InfoOverlay from "../info-overlay/info-overlay";
import s from "./delivery-and-contacts.module.css";

import exit from "../../image/exit.svg";

import sdek from "../../image/CDEK_logo.svg";
import boxberry from "../../image/Boxberry.svg";
import pochta from "../../image/Pochta-logo.svg";

import telega from "../../image/TG-Logo.svg";
import vkontakt from "../../image/VK-Logo.svg";

function LinkBox({img, title, url, modifier}){
    return(<a href={url} className={`${s.box} ${modifier}`} target="_blank"> 
        <img src={img} className={s.image}/>
        <p className={s.subtitile}>{title}</p>
    </a>)
}

function Delivery({setExit}){
    return(<InfoOverlay>
        <a className={s.title}>
            доставка
            <img src={exit} className={s.logo} onClick={() => setExit(false)} alt="крестик"/>
        </a>
        <div className={s.boxes}>
            <LinkBox img={sdek} title="СДЭК" url="https://www.cdek.ru/ru/"/>
            <LinkBox img={boxberry} title="boxberry" url="https://boxberry.ru/"/>
            <LinkBox img={pochta} title="почта" url="https://www.pochta.ru/"/>
        </div>
        <h2 className={s.title_lol}>Доставка бесплатная</h2>
        <p className={s.text}>от 2-х недель, в зависимости от сложности</p>
    </InfoOverlay>)
}

function Contacts({setExit}){
    return(<InfoOverlay>
        <a className={s.title}>
            контакты
            <img src={exit} className={s.logo} onClick={() => setExit(false)} alt="крестик"/>
        </a>
        <p className={s.title_lol}>соцсети</p>
        <div className={s.boxes} style={{justifyContent:"start"}}>
            <LinkBox img={telega} title="телеграм" url="https://t.me/soalla_dress" modifier={s.box_margin}/>
            <LinkBox img={vkontakt} title="вк" url="https://vk.com/soallava"/>
        </div>
        <p className={s.title_lol}>чат для ответов на вопросы</p>
        <div className={s.boxes} style={{justifyContent:"start"}}>
            <LinkBox img={telega} title="Александра" url="https://t.me/Alexandra_Ryz" modifier={s.box_for_contact}/>
        </div>
        
    </InfoOverlay>)
}

export {Delivery, Contacts}