import soallaHeart from "../../image/soalla-heart.svg";
import soalla from "../../image/soalla.svg";
import s from "./header.module.css";
import Menu from "../menu/menu"

import { useState } from "react";

export default function Header() {
    const [menu, setMenu] = useState(false);

    return (<div className={s.header}>
        <img src={soallaHeart} className={s.heart} alt="Соалла сердце" />
        <img src={soalla} className={s.logo} alt="Соалла логотип" />
        <button className={s.menu} onClick={() => setMenu(true)}></button>
        {menu && <Menu setExit={setMenu}/>}
    </div>)
}