import s from "./cnstor_dress.module.css"

import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

import handle from "../../image/handle.svg"

import sashaNeck0 from "../../image/Sasha/Sasha_neck-0.png"
import sashaNeck1 from "../../image/Sasha/Sasha_neck-1.png"
import sashaNeck2 from "../../image/Sasha/Sasha_neck-2.png"
import sashaCB0 from "../../image/Sasha/Sasha_chest_belly-0.png"
import sashaCB1 from "../../image/Sasha/Sasha_chest_belly-1.png"
import sashaCB2 from "../../image/Sasha/Sasha_chest_belly-2.png"
import sashaLegs0 from "../../image/Sasha/Sasha_legs-0.png"
import sashaLegs1 from "../../image/Sasha/Sasha_legs-1.png"
import sashaLegs2 from "../../image/Sasha/Sasha_legs-2.png"

import sonyaNeck0 from "../../image/Sonya/Sonya_neck-0.png"
import sonyaNeck1 from "../../image/Sonya/Sonya_neck-1.png"
import sonyaNeck2 from "../../image/Sonya/Sonya_neck-2.png"
import sonyaCB0 from "../../image/Sonya/Sonya_chest_belly-0.png"
import sonyaCB1 from "../../image/Sonya/Sonya_chest_belly-1.png"
import sonyaCB2 from "../../image/Sonya/Sonya_chest_belly-2.png"
import sonyaLegs0 from "../../image/Sonya/Sonya_legs-0.png"
import sonyaLegs1 from "../../image/Sonya/Sonya_legs-1.png"
import sonyaLegs2 from "../../image/Sonya/Sonya_legs-2.png"

import ladaNeck0 from "../../image/Lada/Lada_neck-0.png"
import ladaNeck1 from "../../image/Lada/Lada_neck-1.png"
import ladaNeck2 from "../../image/Lada/Lada_neck-2.png"
import ladaCB0 from "../../image/Lada/Lada_chest_belly-0.png"
import ladaCB1 from "../../image/Lada/Lada_chest_belly-1.png"
import ladaCB2 from "../../image/Lada/Lada_chest_belly-2.png"
import ladaLegs0 from "../../image/Lada/Lada_legs-0.png"
import ladaLegs1 from "../../image/Lada/Lada_legs-1.png"
import ladaLegs2 from "../../image/Lada/Lada_legs-2.png"



import { useState } from "react";

const colors = {
        "#E9E9E9": "", //беленький
        "#282828": "invert(100%) brightness(80%)",//инвертированный чёрный
        "#B0EDD7": "sepia(4%) saturate(2500%) hue-rotate(105deg) brightness(97%) contrast(85%)", //берюзовый
        "#A30824": "sepia(7%) saturate(3000%) hue-rotate(-60deg) brightness(50%) contrast(250%)", //бордовый
        "#A33B53": "sepia(7.5%) saturate(2250%) hue-rotate(-56deg) brightness(57.5%) contrast(195%)", //Бледно-красный
        "#CC96CC": "sepia(4.5%) saturate(3600%) hue-rotate(-110deg) brightness(87.5%) contrast(65%)", //розовенький
}

function whatDress(dress){
    const itog = [[], [], []]

    if(dress === "прилегающее"){
        itog[0][0]=sashaNeck0; itog[0][1]=sashaNeck1; itog[0][2]=sashaNeck2;
        itog[1][0]=sashaCB0;   itog[1][1]=sashaCB1;   itog[1][2]=sashaCB2; 
        itog[2][0]=sashaLegs0; itog[2][1]=sashaLegs1; itog[2][2]=sashaLegs2; 
    }else if(dress === "полуприлегающее"){
        itog[0][0]=sonyaNeck0; itog[0][1]=sonyaNeck1; itog[0][2]=sonyaNeck2;
        itog[1][0]=sonyaCB0;   itog[1][1]=sonyaCB1;   itog[1][2]=sonyaCB2; 
        itog[2][0]=sonyaLegs0; itog[2][1]=sonyaLegs1; itog[2][2]=sonyaLegs2; 
    }else if(dress === "свободное"){
        itog[0][0]=ladaNeck0; itog[0][1]=ladaNeck1; itog[0][2]=ladaNeck2;
        itog[1][0]=ladaCB0;   itog[1][1]=ladaCB1;   itog[1][2]=ladaCB2; 
        itog[2][0]=ladaLegs0; itog[2][1]=ladaLegs1; itog[2][2]=ladaLegs2; 
    }

    return itog
}


function OneThird({parts, color, MargT, animation}){
    const filterSettings = colors[color] 

    return (
    <Splide id={MargT}
            options={{type:"loop", 
                      wheel: 'true', 
                      updateOnMove:"true", 
                      classes: {pagination: `${s.display_none}`, 
                                arrows:`${s.arrows}`, arrow:`${s.arrow}`,
                                prev:`${s.prev_arrow}`,
		                next:`${s.next_arrow}`,},
                      arrowPath: `${s.display_none}`}}
            className={s.girl__part}
            style={{marginTop: MargT}}
    >   
            <SplideSlide>
                    <img src={parts[0]} className={`${s.neck} ${animation[0] && animation[1]}`} style={{animationDelay:animation[3], filter: filterSettings}} alt='комбинаторика в одежде' />
                    <img src={handle} className={`${s.handle} ${animation[0] && animation[2]}`} style={{animationDelay:animation[3]}}/>
            </SplideSlide>
            <SplideSlide>
                    <img src={parts[1]} className={s.neck} alt='платье по частям'
                    style={{filter: filterSettings}}/>
            </SplideSlide>
            <SplideSlide>
                    <img src={parts[2]} className={s.neck} alt='рандомное платье'
                         style={{filter: filterSettings}}/>
            </SplideSlide>
    </Splide>)
}

export default function CnstorDress({color, dress}){
    const [neck, chest_belly, legs] = whatDress(dress);
    const [anim, setAnim] = useState(true);

    return(<article className={s.girl} onTouchMove={() => setAnim(false)}>
        {/*Потом sashaNeck0, sashaNeck1, sashaNeck2 и т.д. будем получать при клике на платье*/}
        <OneThird parts={[neck[0], neck[1], neck[2]]} color={color} MargT="47%"  
                  animation={[anim, s.move_to_left, s.move_handle_to_left, '2s']}/>
        <OneThird parts={[chest_belly[0], chest_belly[1], chest_belly[2]]} color={color} MargT="-1px" 
                  animation={[anim, s.move_to_right, s.move_handle_to_right, '6s']}/>
        <OneThird parts={[legs[0], legs[1], legs[2]]} color={color} MargT="-2px" 
                  animation={[anim, s.move_to_left, s.move_handle_to_left, '10s']}/>
    </article>)
}