//import Modal from "./modal/modal.jsx";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import sasha from "../../image/Sasha/sasha_dress.jpg";
import sonya from "../../image/Sonya/sonya_dress.jpg";
import lada from "../../image/Lada/lada_dress.jpg";
import s from "./collections.module.css"

import Modal from '../modal/modal';

import { useState } from 'react';


export default function Collections(){
    const [dressCnstor, setDressCnstor] = useState(false);
    const [dressRender, setDressRender] = useState("");

    function settingsDress(whatDress) {
        setDressRender(whatDress);
        setDressCnstor(true);
    }

    return (<>
        <h3 className={s.title}>платья</h3>
        <Splide className={s.collections}
            options={{type:"loop",
                      updateOnMove:"true",
                      classes: {arrows:`${s.arrows}`, arrow:`${s.arrow}`,
                                prev:`${s.prev_arrow}`,
		                        next:`${s.next_arrow}`,},
                      arrowPath: `${s.display_none}`}}>

                <SplideSlide className={s.container}>
                    <img src={sasha} className={s.dress} onClick={() => settingsDress("прилегающее")}/>{/*Саша*/}
                    <p className={s.subtitle}>прилегающее</p>
                </SplideSlide>

                <SplideSlide className={s.container}>
                    <img src={lada} className={s.dress} onClick={() => settingsDress("свободное")}/>{/*Лада*/}
                    <p className={s.subtitle}>свободное</p>
                </SplideSlide>
                
                <SplideSlide className={s.container}>
                    <img src={sonya} className={s.dress} onClick={() => settingsDress("полуприлегающее")}/>{/*Соня*/}
                    <p className={s.subtitle}>полуприлегающее</p>
                </SplideSlide>
                
        </Splide>
        {dressCnstor && <Modal setExit={setDressCnstor} dressRender={dressRender}/>}
    </>)
}