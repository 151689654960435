import InfoOverlay from "../info-overlay/info-overlay";
import s from "./my-order.module.css";
import {createRef, useState} from "react";
import Exit from "../../image/exit.svg"

function Stage({text}){
    return(<div className={s.stage} style={{opacity:"0.25"}}>
        <svg className={s.circle}>
            <circle id="Ellipse 1" cx="12.000000" cy="12.000000" r="12.000000" fill="#FFFFFF" fill-opacity="1.000000"/>
	        <circle id="Ellipse 1" cx="12.000000" cy="12.000000" r="11.500000" stroke="#1D1D1B" stroke-opacity="1.000000" stroke-width="1.000000"/>
        </svg>
        <p className={s.subtitle}>{text}</p>
        <svg className={s.stick}>
            <line id="Line 4" x1="0.500000" y1="0.000000" x2="0.500000" y2="53.000000" stroke="#1D1D1B" stroke-opacity="1.000000" stroke-width="1.000000"/>
        </svg>
    </div>)
}


function InputForm(){
    const [trackNumber, setTrackNumber] = useState('');
    const [errorText, setErrorText] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault(); 
        
        trackNumber === "Саша лох" 
        ? setErrorText("Полный лошпедус!") 
        : setErrorText("Такого трек-номера не существует")
    }

    return(<form className={s.form} onSubmit={handleSubmit}>
        <input className={s.input} type="text" name="order" maxlength="15" placeholder="трек-номер" required
               value={trackNumber} onChange = {(e) => setTrackNumber(e.target.value)}/>
        <span className={s.error}>{errorText}</span>
        <button className={s.arrow} type="submit"></button>
    </form>)
}

export default function MyOrder({setExit}){
    return(<InfoOverlay>
        <a className={s.title}>
            отслеживание
            <img src={Exit} className={s.logo} onClick={() => setExit(false)} alt="крестик"/>
        </a>
        <InputForm />
        <Stage text="оформлен"/>
        <Stage text="подготовка лекал"/>
        <Stage text="шьём"/>
        <Stage text="передан в доставку"/>
        <Stage text="доставлен"/>
    </InfoOverlay>)
}