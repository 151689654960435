import ModalOverlay from "../modal-overlay/modal-overlay";
import { createPortal } from "react-dom";
import { useEffect, useState } from "react";
import s from "./menu.module.css";

import MyOrder from "../my-order/my-order";
import {Delivery, Contacts} from "../delivery-and-contacts/delivery-and-contacts"

export default function Menu({setExit}){
    const ESC_KEY_CODE = 27;

    useEffect(() => {
        document.body.classList.add(`${s.hidden}`);

        //Закрытие по нажатию на esc
        const closeEsc = (e) => {e.keyCode === ESC_KEY_CODE && setExit(false)}
        document.addEventListener('keydown', closeEsc)
        
        //Устанавливаем закрытие по нажатию на тёмный фон.
        const closeBackgound = (e) => {e.target.tagName === 'SECTION' && setExit(false)} //Я решил не прокидвать портал и наржуать всё, а сделать просто сверку с тем section это или нет
        document.addEventListener('click', closeBackgound)
        
        return () => {document.body.classList.remove(`${s.hidden}`);
                      document.removeEventListener('keydown', closeEsc); 
                      document.removeEventListener('click', closeBackgound)}
                      
    },[])

    const [myOrder, setMyOrder] = useState(false);
    const [delivery, setDelivery] = useState(false);
    const [contacts, setContacts] = useState(false);

    return createPortal((<ModalOverlay>
        <nav className={s.menu}>
            <p className={s.text} onClick={() => setMyOrder(true)}>мой заказ</p>
            {myOrder && <MyOrder setExit={setMyOrder}/>}
            <p className={s.text} onClick={() => setDelivery(true)}>доставка</p>
            {delivery && <Delivery setExit={setDelivery}/>}
            <p className={s.text} onClick={() => setContacts(true)}>связь с нами</p>
            {contacts && <Contacts setExit={setContacts}/>}
        </nav>
    </ModalOverlay>), document.body)
}