import s from "./title-arrows.module.css"

function TextPointers({text}){
    return(<li key={text} className={s.title}>
                {text}
           </li>)
}


export default function TitleArrows({one,two,three}){
    return(<ul className={s.all_title}>
        <TextPointers text={one} />
        <TextPointers text={two} />
        <TextPointers text={three} />
    </ul>)
}