import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './componets/App';
import Collections from "./componets/collections/collections"
import Header from './componets/header/header';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('header'));
root.render(
  <React.StrictMode>
    <Header />
  </React.StrictMode>
);

const collections = ReactDOM.createRoot(document.getElementById('collections'));
collections.render(
  <React.StrictMode>
    <Collections />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


function getRandomNumber(min, max) {
  return String(Math.floor(Math.random() * (max - min) + min))
}

const titles = document.querySelectorAll(".welcome__title");
const subTitles = document.querySelectorAll(".welcome__subtitle");

const randomTitle = getRandomNumber(0, titles.length)
const randomSubTitle = getRandomNumber(0, subTitles.length)

titles.forEach(title => {
  if(title.id !== randomTitle){
      title.classList.add("display-none")
  }
})

subTitles.forEach(title => {
  if(title.id !== randomTitle){
      title.classList.add("display-none")
  }
})
