import { useState } from "react"
import s from "./menu-select.module.css"



function MenuSelectColor({title, items, color, setColor}){

    const [openClose, setOpenClose] = useState(false)

    return(<menu className={s.menu}>
        <a className={s.menu__main} onClick={() => setOpenClose(!openClose)} style={{background: color}}>
            {title}
        </a>
        {openClose && items.map((clr) => {
            return (
                <li key={clr} className={s.color} style={{background: clr}} 
                    onClick={() => {setColor(clr); 
                                    setOpenClose(!openClose)}}> 
                </li>
            )})}
    </menu>)
};



function MenuSelectSize({title, items, size, setSize}){

    const [openClose, setOpenClose] = useState(false)

    return(<menu className={s.menu}>
        <a className={s.menu__main} onClick={() => setOpenClose(!openClose)} >{`${title}: ${size}`}</a>
        {openClose && items.map((sz) => {
            return (
                <li key={sz} className={s.text} 
                    onClick={() => {setSize(sz); 
                                    setOpenClose(!openClose)}}>{sz}</li>
            )})}
    </menu>)
};



function MenuSelectMaterial({title, items, material, setMaterial}){

    const [openClose, setOpenClose] = useState(false)


    return(<menu className={s.menu}>
        <a className={s.menu__main} onClick={() => setOpenClose(!openClose)} >{material ? material : title}</a>
        {openClose && items.map((mtr) => {
            return (
                <li key={mtr} className={`${s.text} ${s.text_material}`}
                onClick={() => {setMaterial(mtr); 
                                setOpenClose(!openClose)}}>{mtr}</li>
            )})}
    </menu>)
};

export {MenuSelectColor, MenuSelectSize, MenuSelectMaterial}