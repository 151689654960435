import ModalOverlay from "../modal-overlay/modal-overlay";
import { createPortal } from "react-dom";
import { useEffect } from "react";

import s from "./warning.module.css";

export default function Warning({openPopup}){

    const ESC_KEY_CODE = 27;
    useEffect(() => {
        //Закрытие по нажатию на esc
        const closeEsc = (e) => {e.keyCode === ESC_KEY_CODE && openPopup(false)}
        document.addEventListener('keydown', closeEsc)
        
        //Устанавливаем закрытие по нажатию на тёмный фон.
        const closeBackgound = (e) => {e.target.tagName === 'SECTION' && openPopup(false)} //Я решил не прокидвать портал и наржуать всё, а сделать просто сверку с тем section это или нет
        document.addEventListener('click', closeBackgound)
        
        return () => {document.removeEventListener('keydown', closeEsc); 
                      document.removeEventListener('click', closeBackgound)}
                      
    },[])

    return createPortal((
        <ModalOverlay>
            <div className={s.modal}>
                <p className={s.text}>Онлайн касса будет добавлена в течение<br /> 1-2 недель.<br />
                <br />Сейчас мы принимаем заказы через <a href="https://t.me/Alexandra_Ryz" target="_blank" className={s.link}>telegram</a></p>
            </div>
        </ModalOverlay>
    ), document.body)
}