import s from "./modal.module.css";
import ModalOverlay from "../modal-overlay/modal-overlay.jsx"
import CnstorDress from "../cnstor_dress/cnstor_dress.jsx";
import HeartAndExit from "../heart-and-exit/heart-and-exit";
import TitleArrows from "../title-arrows/title-arrows";
import ManagAssist from "../manag-assist/manag-assist";
import {MenuSelectColor, MenuSelectSize, MenuSelectMaterial} from "../menu-select/menu-select"
import NameCost from "../name-cost/name-cost"

import Photos from "../photos/photos"

import { useEffect, useState } from "react";
import { createPortal } from "react-dom";


export default function Modal({setExit, dressRender}){

    useEffect(() =>{
        document.body.classList.add(`${s.hidden}`);
        return () => {document.body.classList.remove(`${s.hidden}`);}
    },[])
    const [clrDress, setClrDress] = useState("white");
    const [sizeDress, setSizeDress] = useState("42");
    const [clthDress, setClthDress] = useState("");

    return createPortal(
        (<ModalOverlay>
            <div className={s.modal}>
                {/*include*/}
                <div className={s.editor}>
                    <CnstorDress color={clrDress} dress={dressRender}/>{/* Это модальное окно с самой девушкой в платье */}
                    <HeartAndExit setExit={setExit}/>
                    <TitleArrows one="воротник" two="рукава" three="длина"/>
                    <div className={s.menuSelect}>
                        <MenuSelectColor title="цвет" items={["#E9E9E9", "#282828", "#B0EDD7", "#A33B53", "#CC96CC"]}
                                        color={clrDress} setColor={setClrDress}/>
                        <MenuSelectSize title="размер" items={["42", "44", "46", "48"]}
                                        size={sizeDress} setSize={setSizeDress}/>
                        <MenuSelectMaterial title="ткань" items={["крапива", "конопля", "хлопок"]}
                                        material={clthDress} setMaterial={setClthDress}/>
                    </div>

                    <NameCost title={dressRender} term="Срок изготовления ≈ 2 недели" price="5900"/>
                </div>
                <div className={s.info}>

                {/*Таблица размеров*/}
                {/*Правила по уходу*/}
                </div>
                <Photos dress={dressRender}/>
            </div>
        </ModalOverlay>), document.body)
}