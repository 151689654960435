import s from "./photos.module.css";

//Сашино
import fitted1 from "../../image/Sasha/photos/fitted_1.jpg";
import fitted2 from "../../image/Sasha/photos/fitted_2.jpg";
import fitted3 from "../../image/Sasha/photos/fitted_3.jpg";
import fitted4 from "../../image/Sasha/photos/fitted_4.jpg";

//Ладино
import loose1 from "../../image/Lada/photos/loose_4.jpg";
import loose2 from "../../image/Lada/photos/loose_3.jpg";
import loose3 from "../../image/Lada/photos/loose_2.jpg";
import loose4 from "../../image/Lada/photos/loose_1.jpg";

//Сонино
import semiFitted1 from "../../image/Sonya/photos/semi_fitted_1.jpg";
import semiFitted2 from "../../image/Sonya/photos/semi_fitted_2.jpg";
import semiFitted3 from "../../image/Sonya/photos/semi_fitted_3.jpg";
import semiFitted4 from "../../image/Sonya/photos/semi_fitted_4.jpg";

function whatDress(dress){
    const itog = []

    if(dress === "прилегающее"){
        itog[0] = fitted1; 
        itog[1] = fitted2; 
        itog[2] = fitted3;
        itog[3] = fitted4;
    }else if(dress === "полуприлегающее"){
        itog[0] = semiFitted1; 
        itog[1] = semiFitted2; 
        itog[2] = semiFitted3;
        itog[3] = semiFitted4;
    }else if(dress === "свободное"){
        itog[0] = loose1; 
        itog[1] = loose2; 
        itog[2] = loose3;
        itog[3] = loose4;
    }
    return [itog]
}


export default function Photos({dress}){
    const [phts] = whatDress(dress);

    return(<div className={s.photos}>
        <img src={phts[0]} className={s.photo}/>
        <img src={phts[1]} className={s.photo}/>
        <img src={phts[2]} className={s.photo}/>
        <img src={phts[3]} className={s.photo}/>
    </div>)
}